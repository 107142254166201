import ApiService from "@/common/api.service";
import handleReject from "@/common/handleReject";

import {
  CREATE_AUTHOR,
  UPDATE_AUTHOR,
  GET_AUTHORS_LIST,
  FIND_AUTHOR,
  SWITCH_AUTHOR_STATE,
  SET_AUTHOR,
  SET_AUTHORS,
  SET_AUTHOR_ERROR,
  SET_AUTHORS_FOR_ALL, GET_AUTHORS_FOR_ALL,
} from "../types";

const state = {
  author: {},
  authors: [],
  authorsMeta: {},
  authorError: null,
  authorsForAll: []
};

const mutations = {
  [SET_AUTHOR](state, payload){
    state.author = payload;
  },
  [SET_AUTHORS](state, payload){
    let { data: authors, meta } = payload;
    state.authors = authors;
    state.authorsMeta = meta;
  },
  [SET_AUTHOR_ERROR](state, payload){
    state.authorError = payload;
  },
  [SET_AUTHORS_FOR_ALL](state, payload){
    state.authorsForAll = payload;
  },
};

const actions = {
  [CREATE_AUTHOR](context, formData){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post('/manager/authors', formData)
      .then(response=>{
        context.commit(SET_AUTHOR, response.data);
        context.commit(SET_AUTHOR_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_AUTHOR_ERROR);
        reject(response);
      })
    })
  },
  [UPDATE_AUTHOR](context, formData){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.update('/manager/authors', formData.id, formData)
      .then(response=>{
        context.commit(SET_AUTHOR, response.data);
        context.commit(SET_AUTHOR_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_AUTHOR_ERROR);
        reject(response);
      })
    })
  },
  [GET_AUTHORS_LIST](context, options = {page: 1, title: ''}){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/authors', `?page=${options.page}&title=${options.title}`)
      .then(response=>{
        context.commit(SET_AUTHORS, response.data);
        context.commit(SET_AUTHOR_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_AUTHOR_ERROR);
        reject(response);
      })
    })
  },
  [FIND_AUTHOR](context, id){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/authors', id)
      .then(response=>{
        context.commit(SET_AUTHOR, response.data);
        context.commit(SET_AUTHOR_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_AUTHOR_ERROR);
        reject(response);
      })
    })
  },
  [SWITCH_AUTHOR_STATE](context, {id, params}){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(`/manager/authors/${id}/switch`, params)
      .then(response=>{
        context.commit(SET_AUTHOR_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_AUTHOR_ERROR);
        reject(response);
      })
    })
  },
  [GET_AUTHORS_FOR_ALL](context){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/authors/list-for-all')
      .then(response=>{
        context.commit(SET_AUTHORS_FOR_ALL, response.data);
        context.commit(SET_AUTHOR_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_AUTHOR_ERROR);
        reject(response);
      })
    })
  },
};

export default {
  state,
  mutations,
  actions
};
