// [ADVERTS ACTIONS]
export const GET_ADVERTS_LIST = 'getAdvertsList'; // 获取所有的
export const FIND_ADVERTS = 'findAdverts'; // 查找
// [ADVERTS MUTATIONS]
export const SET_ADVERTS = 'setAdverts';
export const SET_ADVERTS_LIST = 'setAdvertsList';
export const SET_ADVERTS_META = 'setAdvertsMeta';
export const SET_ADVERTS_ERROR = 'setAdvertsError';

// [USER ACTIONS]
export const FIND_USER = 'findUser'; // 查找用户
export const GET_USER_LIST = 'getUserList'; // 获取用户列表
export const SWITCH_USER_STATE = 'switchUserState'; // switch 用户的某个属性
// [USER MUTATIONS]
export const SET_USER = 'setUser'; // 设置用户
export const SET_USER_LIST = 'setUserList'; // 设置用户列表
export const SET_USER_META = 'setUserMeta'; // 设置用户分页数据
// [ENTRUST USER ACTIONS]
export const FIND_ENTRUST_USER = 'findEntrustUser'; // 查找用户
export const GET_ENTRUST_USER_LIST = 'getEntrustUserList'; // 获取用户列表
export const GET_ENTRUST_STATICS = 'getEntrustStatics'; // 获取统计数据
// [ENTRUST USER MUTATIONS]
export const SET_ENTRUST_USER = 'setEntrustUser'; // 设置用户
export const SET_ENTRUST_USER_LIST = 'setEntrustUserList'; // 设置用户列表
export const SET_ENTRUST_USER_META = 'setEntrustUserMeta'; // 设置用户分页数据
export const SET_ENTRUST_STATICS = 'setEntrustStatics'; // 设置统计数据
// [USER WALLET ACTIONS]
export const GET_USER_WALLET_LOGS = 'getUserWalletLogs';
// [USER WALLET MUTATIONS]
export const SET_USER_WALLET_LOGS = 'setUserWalletLogs';

// [SETTING ACTIONS]
export const FIND_SETTING = 'findSetting'; // 查找ID对应的配置项
export const GET_SETTING_LIST = 'getSettingList'; // 获取所有的配置项
export const UPDATE_SETTING = 'updateSetting'; // 更新一个配置项
export const UPDATE_SETTING_LIST = 'updateSettingList'; // 更新所有的配置项
export const DELETE_SETTING = 'deleteSetting'; // 删除一个配置项
// [SETTING MUTATIONS]
export const SET_SETTING = 'setSetting'; // 设置一个setting state
export const SET_SETTING_LIST = 'setSettingList'; // 设置一个settingList state

// [MEMBERSHIP ACTIONS]
export const CREATE_MEMBERSHIP = 'createMembership';
export const UPDATE_MEMBERSHIP = 'updateMembership';
export const GET_MEMBERSHIP_LIST = 'getMembershipList';
export const GET_MEMBERSHIP_CONFIG = 'getMembershipConfig';
export const FIND_MEMBERSHIP = 'findMembership';
export const SWITCH_MEMBERSHIP_STATE = 'switchMembershipState';
// [MEMBERSHIP MUTATIONS]
export const SET_MEMBERSHIP = 'setMembership';
export const SET_MEMBERSHIP_LIST = 'setMembershipList';
export const SET_MEMBERSHIP_ERROR = 'setMembershipError';

// [WALLET ACTIONS]
export const FIND_WALLET = 'findWallet'; // 查找用户
export const GET_WALLET_LIST = 'getWalletList'; // 获取用户列表
// [WALLET MUTATIONS]
export const SET_WALLET = 'setWallet'; // 设置用户
export const SET_WALLET_LIST = 'setWalletList'; // 设置用户列表
export const SET_WALLET_META = 'setWalletMeta'; // 设置用户分页数据

// [WALLET CASH REQUEST ACTIONS]
export const GET_WALLET_CASH_REQUEST_LIST = 'getWalletCashRequestList'; // 获取用户列表
// [WALLET CASH REQUEST MUTATIONS]
export const SET_WALLET_CASH_REQUEST = 'setWalletCashRequest'; // 设置用户
export const SET_WALLET_CASH_REQUEST_LIST = 'setWalletCashRequestList'; // 设置用户列表
export const SET_WALLET_CASH_REQUEST_META = 'setWalletCashRequestMeta'; // 设置用户分页数据

// [CATEGORY ACTIONS]
export const CREATE_CATEGORY = 'createCategory';
export const UPDATE_CATEGORY = 'updateCategory';
export const GET_CATEGORIES_LIST = 'getCategoriesList';
export const FIND_CATEGORY = 'findCategory';
export const SWITCH_CATEGORY_STATE = 'switchCategoryState';
export const GET_CATEGORIES_FOR_ALL = 'getCategoriesForAll';
// [CATEGORY MUTATIONS]
export const SET_CATEGORY = 'setCategory';
export const SET_CATEGORIES = 'setCategories';
export const SET_CATEGORY_ERROR = 'setCategoryError';
export const SET_CATEGORIES_FOR_ALL = 'setCategoriesForAll';

// [AUTHOR ACTIONS]
export const CREATE_AUTHOR = 'createAuthor';
export const UPDATE_AUTHOR = 'updateAuthor';
export const GET_AUTHORS_LIST = 'getAuthorsList';
export const FIND_AUTHOR = 'findAuthor';
export const SWITCH_AUTHOR_STATE = 'switchAuthorState';
export const GET_AUTHORS_FOR_ALL = 'getAuthorsForAll';
// [AUTHOR MUTATIONS]
export const SET_AUTHOR = 'setAuthor';
export const SET_AUTHORS = 'setAuthors';
export const SET_AUTHOR_ERROR = 'setAuthorError';
export const SET_AUTHORS_FOR_ALL = 'setAuthorsForAll';

// [NAVIGATIONS ACTIONS]
export const CREATE_NAVIGATION = 'createNavigation';
export const UPDATE_NAVIGATION = 'updateNavigation';
export const GET_NAVIGATIONS_LIST = 'getNavigationsList';
export const FIND_NAVIGATION = 'findNavigation';
export const SWITCH_NAVIGATION_STATE = 'switchNavigationState';
// [NAVIGATIONS MUTATIONS]
export const SET_NAVIGATION = 'setNavigation';
export const SET_NAVIGATIONS = 'setNavigations';
export const SET_NAVIGATION_ERROR = 'setNavigationError';

// [ARTICLES ACTIONS]
export const CREATE_ARTICLE = 'createArticle';
export const UPDATE_ARTICLE = 'updateArticle';
export const GET_ARTICLES_LIST = 'getArticlesList';
export const FIND_ARTICLE = 'findArticle';
export const SWITCH_ARTICLE_STATE = 'switchArticleState';
export const GET_ARTICLES_FOR_ALL = 'getArticlesForAll';
export const GET_ARTICLES_COMMENTS = 'getArticlesComments';
// [ARTICLES MUTATIONS]
export const SET_ARTICLE = 'setArticle';
export const SET_ARTICLES = 'setArticles';
export const SET_ARTICLE_ERROR = 'setArticleError';
export const SET_ARTICLES_FOR_ALL = 'setArticlesForAll';
export const SET_ARTICLES_COMMENTS = 'setArticlesComments';

// [ARTICLE EPISODES ACTIONS]
export const CREATE_ARTICLE_EPISODE = 'createArticleEpisode';
export const UPDATE_ARTICLE_EPISODE = 'updateArticleEpisode';
export const GET_ARTICLE_EPISODES_LIST = 'getArticleEpisodesList';
export const FIND_ARTICLE_EPISODE = 'findArticleEpisode';
export const SWITCH_ARTICLE_EPISODE_STATE = 'switchArticleEpisodeState';
// [ARTICLE EPISODES MUTATIONS]
export const SET_ARTICLE_EPISODE = 'setArticleEpisode';
export const SET_ARTICLE_EPISODES = 'setArticleEpisodes';
export const SET_ARTICLE_EPISODE_ERROR = 'setArticleEpisodeError';

// [ORDER ACTIONS]
export const GET_ORDER_LIST = 'getOrderList';
// [ORDER MUTATIONS]
export const SET_ORDER_LIST = 'setOrderList';
export const SET_ORDERS_ERROR = 'setOrdersError';



// [ CUSTOM MESSAGE MUTATIONS ]
export const SET_CUSTOMER_MESSAGES = 'setCustomerMessages';
export const SET_CUSTOMER_MESSAGES_PAGE = 'setCustomerMessagesPage';
export const SET_CUSTOMER_MESSAGES_META = 'setCustomerMessagesMeta';
// [ CUSTOM MESSAGE ACTIONS ]
export const GET_CUSTOMER_MESSAGES = 'getCustomerMessages';

export const SET_ERROR = 'setError';

export const ID_TOKEN_KEY = "id_token";
